export const availableApps = {
  wolfearn: 'com.wolfearn.easyearning',
  cashmamba: 'com.cashmamba.easyearning',
  mathschamp: 'com.mathschamp.android',
  cashzebra: 'com.cashzebra.android',
  cashbear: 'com.cashbear.app',
  cashwarrior: 'com.cashwarrior.easyearning',
  cashgorilla: 'com.cashgorilla.app',
  rewardsprint: 'com.rewardsprint.android',
  rewardfreak: 'com.rewardfreak.android',
  earnbucks: 'com.bucksearn.earnbucks',
  rewardmax: 'com.rewardmax.android',
  grabreward: 'com.grabreward.android',
  cashrhino: 'com.cashrhino.android',
  earnsamurai: 'com.earnsamurai.android',
  cashverse: 'com.cashverse.android',
  cashloop: 'com.cashloop.android',
}